import React from 'react';
import AgreementTermsSection from '../components/AccountAgreement/AgreementTermsSection';
import BannerSection from '../components/AccountAgreement/BannerSection';
import Layout from '../components/Layout/Layout';

const pageInfo = {
  path: '/account-agreement',
  title: 'Account Agreement',
  description:
    'This Charitable Impact Account Agreement is a legal agreement between you, when you become a user of the Charitable Impact System, and the Charitable Impact Collective.',
  isNavbar: false,
  isFooter: false,
};

const AccountAgreement = () => {
  return (
    <Layout pageInfo={pageInfo}>
      <BannerSection bannerText='Charitable Impact Account Agreement' />
      <AgreementTermsSection />
    </Layout>
  );
};

export default AccountAgreement;
