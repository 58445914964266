import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { VCenter } from "../../styles/globalStyles";

interface Props {
  bannerText: string;
}

const SectionWrap = styled.section`
  ${VCenter}
  background-color: #f2f7fe;
  background-image: url(/legalpages.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 309px;
`;

const BannerText = styled.div``;

const StyledH2 = styled.h2`
  color: #263238;
  font-family: ProximaNova-Semibold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
`;

const BannerSection: React.FC<Props> = ({ bannerText }) => {
  return (
    <SectionWrap>
      <Container>
        <BannerText>
          <StyledH2 className="m-0">{bannerText}</StyledH2>
        </BannerText>
      </Container>
    </SectionWrap>
  );
};

export default BannerSection;
